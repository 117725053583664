<template>
  <div class="zone">
    <TJDetailTitle :titleData="'用量统计_仪器'" />
    <TJDetailMenu :dataList="dataList" />

    <div class="diagram" v-loading.lock="fullscreenLoading">
      <div class="box">
        <div class="diagrammax">金额时序分析图</div>
        <sxfxLineEchart
          :id="'sxfxLineEchartA'"
          :ehartData="ehartDataA"
          :yName="'金额'"
        />
      </div>
      <div class="box">
        <div class="diagrammax">人份数时序分析图</div>
        <sxfxLineEchart
          :id="'sxfxLineEchartB'"
          :ehartData="ehartDataB"
          :yName="'人份数'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'
import sxfxLineEchart from '@/components/sxfxLineEchart.vue'
import TJDetailMenu from '@/components/TJDetailMenu'
import TJDetailTitle from '@/components/TJDetailTitle'
export default {
  name: 'AgencyTime',
  components: { sxfxLineEchart, TJDetailMenu, TJDetailTitle },
  data() {
    return {
      fullscreenLoading: false, //加载中
      dataList: [
        // {
        //   name: '省份',
        //   value: '',
        //   width: '33%',
        //   bottomBorder: false,
        // },
        {
          name: '经销商',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '时间',
          value: '',
          status: '',
          width: '33%',
          bottomBorder: false,
        },
      ],

      query: {
        platform_id: '', //平台标识
        province_id: '', //省份标识
        province_name: '',
        customer_id: '',

        start_stats_year: '', //开始年份 例如:2021
        end_stats_year: '', //结束年份
        start_stats_month: '', //开始月份 例如:202002
        end_stats_month: '', //结束月份
        start_stats_day: '', //开始日期 例如:20210304
        end_stats_day: '', //结束日期
        date_type: '', //日期类型:0:年,1,月,2,日
      },

      ehartDataA: {},
      ehartDataB: {},
    }
  },
  created() {
    this.query = this.$route.query
    this.dataList[0].value = this.query.customer_name
    this.dataList[1].status = this.query.date_type
  },
  mounted() {
    if(this.query.customer_id){
      this.customer_daily_trade_timingFun()
    }else {
      this.customer_daily_trade_entire_timingFun();
    }
  },
  methods: {
    // 经销商全国时序分析分析
    customer_daily_trade_entire_timingFun() {
       this.fullscreenLoading = true
      let param = {
      }
      let start_day,end_day;
      if (this.query.date_type == '年') {
        param.start_stats_year = start_day = this.query.start_stats_year
        param.end_stats_year = end_day = this.query.end_stats_year
        this.dataList[1].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_year,
          this.query.end_stats_year,
        )
      } else if (this.query.date_type == '月') {
        param.start_stats_month = start_day = this.query.start_stats_month
        param.end_stats_month = end_day = this.query.end_stats_month
        this.dataList[1].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_month,
          this.query.end_stats_month,
        )
      } else if (this.query.date_type == '日') {
        param.start_stats_day = start_day = this.query.start_stats_day
        param.end_stats_day = end_day = this.query.end_stats_day
        this.dataList[1].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_day,
          this.query.end_stats_day,
        )
      }
      if(start_day == '' || start_day == undefined ){
        let date = new Date();
        param.start_stats_year =  param.end_stats_year = start_day = end_day = date.getFullYear()
      }
      param.date_type = this.$tool.dateType(start_day,end_day);
      api.customer_daily_trade_entire_timing(param)
      .then((res) => {
          /**
           * 原方法
           */
          // let data = res.data,
          //   list = [],
          //   list2 = [],
          //   nameList = [],
          //   nameList2 = []
          // data.orders_info.forEach((element) => {
          //   let obj = {
          //     name: element.reagent_class_id,
          //     data: element.orders_list,
          //     type: 'line',
          //   }
          //   list2.push(obj)
          //   nameList2.push(element.testing_unique)
          // })
          // this.ehartDataA = {
          //   dataX: data.stats_time,
          //   dataY: list2,
          // }
          // data.amount_info.forEach((element) => {
          //   let obj = {
          //     name: element.reagent_class_id,
          //     data: element.amount_list,
          //     type: 'line',
          //   }
          //   list.push(obj)
          //   nameList.push(element.testing_unique)
          // })
          // this.ehartDataB = {
          //   dataX: data.stats_time,
          //   dataY: list,
          // }
          /**
           * 新处理方式
           * 解决后端传过来的数据和时间无法一一对应的问题
           */
          let data = res.data,
            list = [],
            list2 = [],
            allData = [],
            nameList = [],
            nameList2 = [],
            allData2 = [];
          data.orders_info.forEach(element => {
            data.stats_time.forEach((tiemTemp,index)=>{
              if(tiemTemp != element.stats_time[index]){
                element.stats_time.splice(index,0,tiemTemp)
                element.orders_list.splice(index,0,null)
              }else{
                allData2[index]?allData2[index]+=Number(element.orders_list[index]):allData2[index]=Number(element.orders_list[index])
              }
            })
            let obj = {
              name: element.reagent_class_id,
              data: element.orders_list,
              connectNulls: true,
              type: 'line',
            }
            list2.push(obj)
            nameList2.push(element.reagent_class_id)
          })
          list2.unshift({
            name: '全部',
            data: allData2,
            connectNulls: true,
            type: 'line',
          })
          this.ehartDataA = {
            dataX: data.stats_time,
            dataY: list2,
          }
          
          data.amount_info.forEach((element) => {
            data.stats_time.forEach((tiemTemp,index)=>{
              if(tiemTemp != element.stats_time[index]){
                element.stats_time.splice(index,0,tiemTemp)
                element.amount_list.splice(index,0,null)
              }else{
                allData[index]?allData[index]+=Number(element.amount_list[index]):allData[index]=Number(element.amount_list[index])
              }
            })
            let obj = {
              name: element.reagent_class_id,
              data: element.amount_list,
              connectNulls: true,
              type: 'line',
            }
            list.push(obj)
            nameList.push(element.reagent_class_id)
          })
          list.unshift({
            name: '全部',
            data: allData,
            connectNulls: true,
            type: 'line',
          })
          this.ehartDataB = {
            dataX: data.stats_time,
            dataY: list,
          }
          this.fullscreenLoading = false
        })
        .catch((err) => {
          this.fullscreenLoading = false
        })
    },
    // 省份销量统计构成分析
    customer_daily_trade_timingFun() {
      this.fullscreenLoading = true
      let param = {
        province_id: this.query.province_id, //省份标识
        customer_id: this.query.customer_id, //经销商标识
      }
      let start_day,end_day;
      if (this.query.date_type == '年') {
        param.start_stats_year = start_day = this.query.start_stats_year
        param.end_stats_year = end_day = this.query.end_stats_year
        this.dataList[1].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_year,
          this.query.end_stats_year,
        )
      } else if (this.query.date_type == '月') {
        param.start_stats_month = start_day = this.query.start_stats_month
        param.end_stats_month = end_day = this.query.end_stats_month
        this.dataList[1].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_month,
          this.query.end_stats_month,
        )
      } else if (this.query.date_type == '日') {
        param.start_stats_day = start_day = this.query.start_stats_day
        param.end_stats_day = end_day = this.query.end_stats_day
        this.dataList[1].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_day,
          this.query.end_stats_day,
        )
      }
      if(start_day == '' || start_day == undefined ){
        let date = new Date();
        param.start_stats_year =  param.end_stats_year = start_day = end_day = date.getFullYear()
      }
      param.date_type = this.$tool.dateType(start_day,end_day);
      api
        .customer_daily_trade_timing(param)
        .then((res) => {
          /**
           * 原方法
           */
          // let data = res.data,
          //   list = [],
          //   list2 = [],
          //   nameList = [],
          //   nameList2 = []
          // data.orders_info.forEach((element) => {
          //   let obj = {
          //     name: element.reagent_class_id,
          //     data: element.orders_list,
          //     type: 'line',
          //   }
          //   list2.push(obj)
          //   nameList2.push(element.testing_unique)
          // })
          // this.ehartDataA = {
          //   dataX: data.stats_time,
          //   dataY: list2,
          // }
          // data.amount_info.forEach((element) => {
          //   let obj = {
          //     name: element.reagent_class_id,
          //     data: element.amount_list,
          //     type: 'line',
          //   }
          //   list.push(obj)
          //   nameList.push(element.testing_unique)
          // })
          // this.ehartDataB = {
          //   dataX: data.stats_time,
          //   dataY: list,
          // }
          /**
           * 新处理方式
           * 解决后端传过来的数据和时间无法一一对应的问题
           */

          let data = res.data,
            list = [],
            list2 = [],
            allData = [],
            nameList = [],
            nameList2 = [],
            allData2 = [];
          data.orders_info.forEach(element => {

            data.stats_time.forEach((tiemTemp,index)=>{
              if(tiemTemp != element.stats_time[index]){
                element.stats_time.splice(index,0,tiemTemp)
                element.orders_list.splice(index,0,null)
              }else{
                allData2[index]?allData2[index]+=Number(element.orders_list[index]):allData2[index]=Number(element.orders_list[index])
              }
            })
            let obj = {
              name: element.reagent_class_id,
              data: element.orders_list,
              connectNulls: true,
              type: 'line',
            }
            list2.push(obj)
            nameList2.push(element.reagent_class_id)

          })

          list2.unshift({
            name: '全部',
            data: allData2,
            connectNulls: true,
            type: 'line',
          })
          this.ehartDataA = {
            dataX: data.stats_time,
            dataY: list2,
          }
          data.amount_info.forEach((element) => {
            data.stats_time.forEach((tiemTemp,index)=>{
              if(tiemTemp != element.stats_time[index]){
                element.stats_time.splice(index,0,tiemTemp)
                element.amount_list.splice(index,0,null)
              }else{
                allData[index]?allData[index]+=Number(element.amount_list[index]):allData[index]=Number(element.amount_list[index])
              }
            })
            let obj = {
              name: element.reagent_class_id,
              data: element.amount_list,
              connectNulls: true,
              type: 'line',
            }
            list.push(obj)
            nameList.push(element.reagent_class_id)
          })
          list.unshift({
            name: '全部',
            data: allData,
            connectNulls: true,
            type: 'line',
          })
          this.ehartDataB = {
            dataX: data.stats_time,
            dataY: list,
          }

          this.fullscreenLoading = false
        })
        .catch((err) => {
          this.fullscreenLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .TJDetailMenu .box p:nth-child(2) {
  font-weight: bold;
}
.diagram {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  .box {
    width: 50%;
    .diagrammax {
      margin-left: 15px;
      font-weight: bold;
      padding-top: 10px;
    }
  }
}
</style>
